import React, { useEffect, useRef, useState } from "react";
import "./WatchClassTemplate.css";
import ClassWatchVideoAtom from "../../atomos/ClassWatchVideoAtom";
import CreateNewClassModalOrganisms from "../../organisms/createNewClassModalOrganisms/CreateNewClassModalOrganisms";
import CourseListSubmoduleCoursesManagerPage from "../../pages/courseListSubmoduleCoursesManagerPage/CourseListSubmoduleCoursesManagerPage";
import { Avatar, Collapse, Divider, Flex, Typography } from "antd";
import { AccountService, ClassService, ClassWatchDesktopService, CommentClassService, CourseService, CourseSubModuleService } from "cursiverso-client-module/client-web";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { selectIsChatEnabled, setCurrentClassWatchRedux } from "../../features/currentClassWatchSlice/currentClassWatchSlice";
import { useSelector } from 'react-redux';
import Post from "./../../atomos/CommentsView";
import { setAccountDetails } from "../../features/account/accountSlice";
import { PlayCircleOutlined, UserOutlined } from "@ant-design/icons";
export default function WatchClassTemplate() {
    const [comments, setComments] = useState([]);
    const [loadingComments, setLoadingComments] = useState(true);
    const [groupBy, setGroupBy] = useState({})
    const [listGroupBy, setListGroupBy] = useState([])
    const [listCourse, setListCourse] = useState([])
    const [currentClassWatch, setCurrentClassWatch] = useState("")
    const [currentClassAllInfo, setCurrentClassAllInfo] = useState({})
    const [account, setAccount] = useState()
    const dispatch = useDispatch();
    const listCourseCurrent = useRef()
    const currentClassCurrent = useRef()
    const isChatEnabled = useSelector(selectIsChatEnabled);
    const [key, setKey] = useState(0);
    const [isOpen, setIsOpen] = React.useState(false);
    // id_course_submodule is true id_course
    var { code, id_course_submodule, id_class } = useParams()

    const refCollapse = React.useRef(null)
    useEffect(() => {
        setKey(prevKey => prevKey + 1);
    }, [isChatEnabled]);

    useEffect(() => {
        ClassWatchDesktopService.returnClassWatchDesktopByCode(code).then((response) => {
            AccountService.returnAccountById(response.data.id_account).then((response) => {
                console.log("SETED ACCOUNT", response.data)
                setAccount(response.data)
            })
        })

        console.log("ID_CLASS", id_class)

        // id_course_submodule is true id_course
        CourseService.returnAllCourses().then((response) => {
            var filtered = []
            if (id_class) {
                response.data.forEach(course => {
                    course.submodules.forEach(submodule => {
                        if (submodule._id.$oid == id_course_submodule) {
                            id_course_submodule = course._id.$oid
                        }
                    });
                });
            }
            filtered = response.data.filter((item) => item._id.$oid == id_course_submodule)
            var listClasses = [];
            filtered[0].submodules.forEach(element => {
                element.classes.forEach(element => {
                    listClasses.push(element);
                });
            });
            setListCourse(listClasses)
            listCourseCurrent.current = listClasses
            if (id_class) {
                setCurrentClassWatch(id_class)
                currentClassCurrent.current = id_class
            } else {
                setCurrentClassWatch(listCourseCurrent.current[0]._id.$oid)
            }
        }).catch((error) => {
            console.error(error)
        }).finally(() => {
            console.log("FINALLY")
        })
    }, [])

    useEffect(() => {
        dispatch(setAccountDetails(account))
        // Restante do código...
    }, [isChatEnabled]);

    useEffect(() => {
        // Restante do código...
    }, [currentClassWatch]);

    useEffect(() => {
        const fetchComments = async () => {
            try {
                const response = await CommentClassService.returnCommentClassByClassId(currentClassCurrent.current);
                setComments(response.data);
            } catch (error) {
                console.error('Error fetching comments:', error);
                // Handle error
            } finally {
                setLoadingComments(false);
            }
        };

        fetchComments();
    }, []);
    useEffect(() => {

    }, [listGroupBy])

    useEffect(() => {
        const currentClass = listCourse.find((item) => item._id.$oid == currentClassWatch)
        setCurrentClassAllInfo(currentClass)
        dispatch(setCurrentClassWatchRedux(currentClassWatch));
    }, [currentClassWatch])

    function groupByKey(objectArray, property) {
        return objectArray.reduce(function (acc, obj) {
            let key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    function returnListOfObjectsKeys(objectArray) {
        let list = []
        for (let key in objectArray) {
            list.push(key)
        }

        return list
    }

    function capitalizeFirstLetter(string) {
        if (string)
            return string.charAt(0).toUpperCase() + string.slice(1);
        else
            return ""
    }

    function returnPhotoProfile(value) {
        if (value === undefined || value === null || value === "") {
            return (
                <Avatar size={50} icon={<UserOutlined />} />
            )
        } else {
            return (
                <Avatar size={50} src={value} />
            )
        }
    }

    return (
        <div className="root-watch-class">
            <div style={{ width: "100%", display: "flex", justifyContent: "center", boxShadow: "10px 0px 10px #1D1B18", marginBottom: 10 }}>
                <div style={{ width: "96%", height: 100, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            window.location.href = "/"
                        }}>
                        <img src="https://redcanidsstorage.blob.core.windows.net/redcanids/da8fff9c-a89e-4904-99b1-da1e8fca3794.png" alt="logo" className="logo-vertical-menu" style={{ width: 100, height: 100 }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 10 }}>
                        <Typography.Text style={{ color: "white" }}>Olá {capitalizeFirstLetter(account?.name)}</Typography.Text>
                        {returnPhotoProfile(account?.photo)}
                    </div>
                </div>
            </div>
            <div style={{ width: "96vw", justifyContent: "flex-start", alignItems: "flex-start", display: "flex", flexDirection: "column" }}>
                <Typography style={{ color: "#59399D", textAlign: "center", fontWeight: 700 }}>{currentClassAllInfo?.section}</Typography>
                <Typography.Title level={4} style={{ color: "white", textAlign: "center", marginTop: 0 }}>#Aula - {currentClassAllInfo?.title}</Typography.Title>
            </div>
            <Flex justify="space-evenly" style={{
                width: "100%",
                height: "100%",
            }}>
                <ClassWatchVideoAtom src={currentClassAllInfo?.course_url} courses={currentClassAllInfo?.courses} account={account} courseAllinfo={currentClassAllInfo} />
                <div style={{ width: "30vw", overflow: isChatEnabled ? "scroll" : "hidden", minHeight: "70vh", maxHeight: "70vh", marginLeft: 20 }}>
                    {isChatEnabled ? (
                        <motion.div
                            id="Classes"
                            key={key}
                            initial={{ opacity: 0, x: "-50%" }}
                            animate={{ opacity: 1, x: "0%" }}
                            exit={{ opacity: 0, x: "50%" }}
                        >
                            <Typography.Title level={5} style={{ color: "white", marginLeft: 25 }}>{"Próximas aulas"}</Typography.Title>
                            {
                                listCourse.map((item) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                setCurrentClassWatch(item._id.$oid)
                                            }}
                                            style={{
                                                gap: 10,
                                                // color purple with opacity 0.5
                                                backgroundColor: currentClassWatch == item._id.$oid ? "#59399D" : "transparent",
                                                borderRadius: 10,
                                                marginTop: 10,
                                                height: 80,
                                                width: "90%",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                cursor: "pointer",
                                            }}>
                                            <div style={{ display: "flex", width: "100%", height: "100%" }}>
                                                <div style={{ flex: 1, width: "100%", height: "100%", alignItems: "center", display: "flex", justifyContent: "center" }}>
                                                    <img src={item.image_course} alt="course" style={{ width: 60, height: 60, objectFit: "cover", borderRadius: 10 }} />
                                                </div>
                                                <div style={{ flex: 4, width: "100%", height: "100%", alignItems: "flex-start", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                                    <Typography.Text level={5} style={{ color: "white", fontWeight: 900 }}>{item.section}</Typography.Text>
                                                    <Typography.Text level={5} style={{ color: "white" }}>{item.title}</Typography.Text>
                                                    {/* <Typography.Text level={5} style={{ color: "white", fontWeight: 200 }}>  {item.description?.length > 100 ? `${item.description.substring(0, 70)}...` : item.description}</Typography.Text> */}
                                                </div>
                                                <div style={{ flex: 1, width: "100%", height: "100%", alignItems: "center", display: "flex", justifyContent: "center" }}>
                                                    {/* <img src={"https://cursiversostorage.blob.core.windows.net/miosacademyimages/c9d1cec4-bbbc-4216-a4ed-e742d0a4e0ff.png"} alt="course" style={{ width: 32, height: 32, objectFit: "cover", borderRadius: 10 }} /> */}
                                                    <PlayCircleOutlined style={{ color: "white", fontSize: 24 }} />
                                                </div>
                                            </div>
                                            <div style={{ width: "100%", height: .5, backgroundColor: "rgba(255, 255, 255, .1)", }} />
                                        </div>
                                    )
                                }
                                )
                            }
                        </motion.div>
                    ) : (
                        <motion.div
                            id="Comments"
                            key={key}
                            initial={{ opacity: 0, x: "-50%" }}
                            animate={{ opacity: 1, x: "0%" }}
                            exit={{ opacity: 0, x: "-50%" }}
                            style={{ height: "100%", maxHeight: "100%" }}
                        >
                            <Post comments={comments} loading={loadingComments} />
                        </motion.div>
                    )}
                </div>
            </Flex>
        </div >
    );
}