import { Button, Flex, Form, Input, Select, Upload, Alert, Space, DatePicker, DatePickerProps } from "antd";
import { ClassService, OpenAPI } from "cursiverso-client-module/client-web";
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import React, { useEffect } from "react";

export default function CreateNewClassModalOrganisms({ handleOnClose, handleOnCreate, id_course_submodule }) {
    /**
     * This component is a modal that allows the user to create a new class.
     */
    // OpenAPI.BASE = "http://localhost:8000"
    const options = [];
    const [video, setVideo] = React.useState(null)
    const [image, setImage] = React.useState(null)
    const [files, setFiles] = React.useState([])
    const [allSectoins, setAllSections] = React.useState([])

    const [form] = Form.useForm();

    for (let i = 10; i < 36; i++) {
        options.push({
            value: i.toString(36) + i,
            label: i.toString(36) + i,
        });
    }
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const props = {
        name: 'file',
        action: "https://20.19.34.80" + '/image/add-video',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                console.log("here", info.file.response.files[0].url)
                setVideo(info.file.response.files[0].url)
            } else if (info.file.status === 'error') {
                console.log("error", info.file.response);
            }
        },
    };

    const propsImage = {
        name: 'file',
        action: OpenAPI.BASE + '/image/add-image',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                console.log("here", info.file.response.files[0].url)
                setImage(info.file.response.files[0].url)
            } else if (info.file.status === 'error') {
                console.log("error", info.file.response);
            }
        },
    };

    const propsFiles = {
        name: 'file',
        action: OpenAPI.BASE + '/image/add-file',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                console.log("files", info.file.response.files)
                const data = {
                    name: info.file.response.files[0].name,
                    url: info.file.response.files[0].url,
                    type: info.file.response.files[0].type
                }
                setFiles([...files, data])
            } else if (info.file.status === 'error') {
                console.log("error", info.file.response);
            }
        },
    };

    const handleOnFinish = (value) => {
        let dataToSave = value
        dataToSave.section = value.section[0]
        dataToSave.image_course = image
        dataToSave.video_course = video
        dataToSave.materials = files
        dataToSave.id_course_submodule = id_course_submodule
        console.log(dataToSave)
        ClassService.createClass(dataToSave).then((response) => {
            console.log(response)
            form.resetFields()
            setFiles([])
            setImage(null)
            setVideo(null)
            handleOnCreate()
        }).catch((error) => {
            console.error(error)
        }).finally(() => {
            console.log("FINALLY")
        })
    }

    const handleOnfail = () => {
        console.log("error")
    }

    useEffect(() => {
        ClassService.returnAllClasses().then((response) => {
            //not duplicated
            let sections = response.data.map((item) => {
                return {
                    label: item.section,
                    value: item.section
                }
            })
            sections = sections.filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.value === item.value
                ))
            )
            console.log(sections)
            setAllSections(sections)
        }).catch((error) => {

        }).finally(() => {

        })
    }, [])

    return (
        <Flex>
            <Form
                form={form}
                labelAlign="left"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                style={{
                    width: "100%",
                }}
                layout="vertical"
                autoComplete="off"
                onFinish={handleOnFinish}
                onFinishFailed={handleOnfail}
            >
                <Form.Item
                    label="Nome da aula"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor, insira o nome da aula!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Imagem do curso"
                    name="image_course"
                    rules={[
                        {
                            validator: (_, value) => {
                                if (image === null) {
                                    return Promise.reject('Por favor, insira a imagem do curso!');
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                >
                    <Flex>
                        <Upload className="upload-file" accept={"image/png, image/jpeg, image/jpg"} {...propsImage} maxCount={1}>
                            <Button id="button-upload-id-454632" className="button-upload-file" icon={<UploadOutlined />}>
                                <div style={{ margin: 10, backgroundColor: "white", borderRadius: 10 }}>
                                    <p>Selecione a imagem do curso</p>
                                    <p>PNG, JPG e JPGE</p>
                                </div>
                            </Button>
                        </Upload>
                    </Flex>
                </Form.Item>

                {
                    image != null && (
                        <Alert
                            message="Parabéns"
                            description="Upload da imagem realizado com sucesso!"
                            type="success"
                            showIcon
                            icon={<InfoCircleOutlined />}
                            style={{ marginBottom: 20, marginTop: 20 }}
                        />
                    )
                }

                <br />

                <Form.Item
                    label="Seção"
                    name="section"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor, insira a seção!',
                        },
                    ]}
                >
                    <Select
                        mode="tags"
                        style={{
                            width: '100%',
                        }}
                        onChange={handleChange}
                        tokenSeparators={[',']}
                        options={allSectoins}
                        maxCount={1}
                    />
                </Form.Item>

                <Form.Item
                    label="Aula conta como iniciada?"
                    name="started_verification"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor, insira se a aula conta como iniciada!',
                        },
                    ]}
                >
                    <Select>
                        <Select.Option value={true}>Sim</Select.Option>
                        <Select.Option value={false}>Não</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Data prevista de lançamento"
                    name="expected_release_date"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor, insira a data prevista de lançamento!',
                        },
                    ]}
                >
                    <DatePicker 
                        style={{width: "100%"}}
                    />
                </Form.Item>
                <Form.Item
                    label="Descrição"
                    name="description"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor, insira a descrição da aula!',
                        },
                    ]}
                >
                    <Input.TextArea placeholder="Descrição da aula" style={{ height: 200 }} />
                </Form.Item>

                <Form.Item
                    label="Material de apoio"
                >
                    <Flex>
                        <Upload className="upload-file-files" accept={".pdf"} {...propsFiles} maxCount={10} multiple>
                            <Button id="button-upload-id-454632" className="button-upload-file" icon={<UploadOutlined />}>
                                <div style={{ margin: 10, backgroundColor: "white", borderRadius: 10 }}>
                                    <p>Selecione os arquivos</p>
                                    <p>PDF</p>
                                </div>
                            </Button>
                        </Upload>
                    </Flex>
                </Form.Item>

                <br />
                <Form.Item
                    label="Vídeo da aula"
                    name="video_course"
                    rules={[
                        {
                            validator: (_, value) => {
                                if (video === null) {
                                    return Promise.reject('Por favor, insira o vídeo da aula!');
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                >
                    <Flex>
                        <Upload className="upload-file" accept={"video/mp4, .mov"} {...props} maxCount={1}>
                            <Button id="button-upload-id-454632" className="button-upload-file" icon={<UploadOutlined />}>
                                <div style={{ margin: 10, backgroundColor: "white", borderRadius: 10 }}>
                                    <p>Selecione o vídeo</p>
                                    <p>MP4</p>
                                </div>
                            </Button>
                        </Upload>
                    </Flex>
                </Form.Item>

                {
                    video != null && (
                        <Alert
                            message="Parabéns"
                            description="Upload do vídeo realizado com sucesso!"
                            type="success"
                            showIcon
                            icon={<InfoCircleOutlined />}
                            style={{ marginBottom: 20, marginTop: 20 }}
                        />
                    )
                }
                <Form.Item>
                    <Flex >
                        <Button type="primary" htmlType="submit" style={{ width: 500 }}>
                            Criar aula
                        </Button>
                    </Flex>
                </Form.Item>
            </Form>
        </Flex>
    )
}