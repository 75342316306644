import React from "react";

export default function CardCourse({ _id, img, is_editable, handleOnClick }) {
    /**
     * This component is a card that represents a course.
     */
    return (
        <div onClick={() => {
            handleOnClick(_id)
        }} style={{ height: 440, justifyContent: "center", alignItems: "center", display: "flex", cursor: "pointer" }}>
            <div className="shadow-default" style={{ height: 400, width: 300, borderRadius: 10, overflow: "hidden" }}>
                <img src={img} alt="course" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
            </div>
        </div>
    )
}