import React from "react";
import { Button, Flex, Layout, Menu, theme } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserAddOutlined,
} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import TextBoldH3 from "../../atomos/TextBoldH3";
import { closeDrawer, openDrawer } from "../../features/drawer/drawer";
import "./BaseLayout.css";
import { Typography } from 'antd';

export default function BaseLayout({ currentKey, content }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const drawer = useSelector((state) => state.drawer);
    const colors = require("../../styles/Colors.json");

    const { Header, Sider, Content } = Layout;
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    function chooseNavigateTo(key) {
        switch (key) {
            case "CourseManager":
                return "/manager"
            case "StatusProcess":
                return "/status-process"
            default:
        }
    }


    function navigateTo(key) {
        const path = chooseNavigateTo(key)
        navigate(path)
    }

    return (
        <>
            <Layout className="layout-base-layout">
                <Sider trigger={null} collapsible collapsed={drawer} width={300} style={{ position: "relative", backgroundColor: "#49278F" }}>
                    <div className="div-vertical-menu" onClick={() => drawer ? dispatch(closeDrawer()) : dispatch(openDrawer())}>
                        <img src="https://redcanidsstorage.blob.core.windows.net/redcanids/da8fff9c-a89e-4904-99b1-da1e8fca3794.png" alt="logo" className="logo-vertical-menu" />
                    </div>
                    <Menu
                        theme="dark"
                        mode="inline"
                        style={{ backgroundColor: "#49278F" }}
                        defaultSelectedKeys={['CourseManager']}
                        selectedKeys={currentKey}
                        onSelect={({ key }) => navigateTo(key)}
                        items={[
                            {
                                key: 'CourseManager',
                                icon: <UserAddOutlined />,
                                label: 'Gerenciar cursos',
                            },
                            {
                                key: 'StatusProcess',
                                icon: <UserAddOutlined />,
                                label: 'Processamento da aula',
                            },
                        ]}
                    />
                    {/* <div style={{ cursor: "pointer", position: "fixed", width: drawer ? 80 : 300, bottom: 20, height: 50, justifyContent: "center", display: "flex", alignItems: "center" }} onClick={() => {
                        localStorage.removeItem("@adminData");
                        navigate("/login/manager")
                    }}>
                        <LogoutOutlined />
                    </div> */}
                </Sider>
                <Layout>
                    <Header
                        style={{
                            padding: 0,
                            background: colorBgContainer,
                            display: 'flex',
                        }}
                    >

                        <Flex style={{ width: "100%", marginLeft: 20, marginRight: 20 }} >
                            <Button
                                type="text"
                                icon={drawer ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                onClick={() => { drawer ? dispatch(closeDrawer()) : dispatch(openDrawer()) }}
                                style={{
                                    fontSize: '16px',
                                    width: 64,
                                    height: 64,
                                }}
                            />
                            <Flex flex={1} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {/* <TextBoldH3 text={"Painel de gestão"} colorText={colors.GrayscaleSlot6} /> */}
                                <Typography.Title level={3} style={{ color: colors.GrayscaleSlot6 }}>Painel de gestão</Typography.Title>
                            </Flex>
                            <Flex style={{ width: 64, height: 64, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {/* <img src="https://redcanidsstorage.blob.core.windows.net/redcanids/Logo_RC_Big.png" alt="logo" className="home-logo" /> */}
                            </Flex>
                        </Flex>

                    </Header>
                    <Content
                        style={{
                            margin: '24px 16px',
                            // padding: 24,
                            minHeight: 280,
                            borderRadius: borderRadiusLG,
                            overflow: "scroll",
                        }}
                    >
                        {content}
                    </Content>
                </Layout>
            </Layout>
        </>
    );
}