import { configureStore } from '@reduxjs/toolkit';
import drawerFeatures from '../features/drawer/drawer';
import currentClassWatchReducer from '../features/currentClassWatchSlice/currentClassWatchSlice';
import commentsReducer from '../features/comments/comments';
import accountReducer from './../features/account/accountSlice'

const rootReducer = {
    drawer: drawerFeatures,
    currentClassWatch: currentClassWatchReducer,
    comments: commentsReducer,
    account: accountReducer,
};

const store = configureStore({
    reducer: rootReducer,
});

export default store;
