import { Button, Input, Typography, notification } from "antd";
import { AccountService } from "cursiverso-client-module/client-web";
import React from "react";
import { useNavigate, useNavigation } from "react-router-dom";

export default function LoginPage() {
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [api, contextHolder] = notification.useNotification();

    const navigation = useNavigate();

    const openNotificationError = () => {
        api.open({
          message: 'Erro',
          type: 'error',
          description:
            'E-mail ou senha incorretos, tente novamente.',
          duration: 3,
        });
      };
    

    return (
        <div style={{ height: "100vh", margin: 0, padding: 0, backgroundColor: "black", display: "flex", justifyContent: "center", alignItems: "center", position: "relative", overflow: "hidden", backgroundImage: `url("https://cursiversostorage.blob.core.windows.net/miosacademyimages/acd9454a-71bf-4ef9-87fc-1e0e82eadd3c.png")`, backgroundSize: "cover" }}>
            {contextHolder}
            <div className="card-qrcode-invite shadow-default">
                <div style={{ justifyContent: "center", display: "flex" }}>
                    <img src="https://redcanidsstorage.blob.core.windows.net/redcanids/da8fff9c-a89e-4904-99b1-da1e8fca3794.png" alt="logo" className="logo-vertical-menu" style={{ width: 100, height: 100 }} />
                </div>
                <div style={{ justifyContent: "center", alignItems: "center", display: "flex", height: 200 }}>
                    <Typography.Title level={3} style={{ color: "white", textAlign: "center", fontWeight: 100 }}>Faça login no Mios Academy</Typography.Title>
                </div>
                <div style={{ flexDirection: "column", display: "flex", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography.Title level={5} style={{ color: "white", textAlign: "center", fontWeight: 100 }}>E-mail</Typography.Title>
                    <Input placeholder="E-mail" style={{ width: 300, margin: 10 }} onChange={(e) => setEmail(e.target.value)}/>
                    <Typography.Title level={5} style={{ color: "white", textAlign: "center", fontWeight: 100 }}>Senha</Typography.Title>
                    <Input placeholder="Senha" style={{ width: 300, margin: 10 }} onChange={(e) => setPassword(e.target.value)} type="password"/>
                    <Button type="primary" style={{ width: 300, margin: 10 }} onClick={() => {
                        console.log("email", email, "senha", password)
                        AccountService.returnAccountByEmailAndPassword(email, password).then((response) => {
                            navigation("/aula/610072/65c936c98b98ba6ea3c3f198/65c938c28b98ba6ea3c3f199")
                        }).catch((error) => {
                            console.error(error)
                            openNotificationError()
                        })
                    }}>Entrar</Button>
                </div>
            </div>
        </div>
    )
}