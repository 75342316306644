import React, { useEffect } from "react";
import { Space, Table, Tag } from 'antd';
import { ClassService } from "cursiverso-client-module/client-web"

const columns = [
    // {
    //     title: 'ID',
    //     dataIndex: 'name',
    //     key: 'name',
    //     render: (text) => <p>{text}</p>,
    // },
    {
        title: "Nome aula",
        dataIndex: 'nameClass',
        key: 'nameClass',
        render: (text) => <p>{text}</p>,
    },
    {
        title: "Resolução a processar",
        dataIndex: 'resolution',
        key: 'resolution',
        render: (text) => <p>{text}</p>,
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (text) => <p>{text}</p>,
    },
    {
        title: 'Tamanho',
        dataIndex: 'size',
        key: 'size',
        render: (text) => <p>{parseFloat(text).toFixed(2) + " MB"}</p>,
    }
];

export default function StatusProcessTemplate() {

    const [data, setData] = React.useState([]);

    function extractUUID(str) {
        const regex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;
        const match = str.match(regex);
        return match ? match[0] : null; // Retorna o UUID se encontrado, senão retorna null
    }

    function extractResolution(str) {
        const regex = /_(\d+K?|FHD)\.mp4/i; // Adapte esta expressão regular conforme necessário
        const match = str.match(regex);
        return match ? match[1] : null; // Retorna a resolução se encontrada, senão retorna null
    }

    function orderByName(a, b) {
        if (a.nameClass < b.nameClass) {
            return -1;
        }
        if (a.nameClass > b.nameClass) {
            return 1;
        }
        return 0;
    }

    async function getProcess() {
        const responseVideos = await fetch("https://20.19.34.80/class/videos/to/process");
        const dataVideos = await responseVideos.json();

        const reponseClass = await ClassService.returnAllClasses();
        const dataClass = await reponseClass;

        var dataToTable = dataVideos?.data?.map((video) => {
            const uuidVideo = extractUUID(video.name);
            video.resolution = extractResolution(video.name) == null ? "Original" : extractResolution(video.name);
            video.date = new Date(video.date).toLocaleString('pt-BR', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            });
            var classData = dataClass.data.find((classData) => extractUUID(classData.course_url) == uuidVideo);
            return {
                ...video,
                nameClass: classData?.title == undefined ? "Aula não salva pelo usuário" : classData?.title
            }
            // var classData = dataClass.data.find((classData) => classData.id === uuidVideo);
            // return {
            //     ...video,
            //     nameClass: classData.name
            // }
        });
        setData(dataToTable?.sort(orderByName));
    }

    useEffect(() => {
        var interval = setInterval(() => {
            getProcess();
        }, 10000);
        getProcess();
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={{ height: "100%", width: "100%" }}>
            <Table
                columns={columns}
                dataSource={data}
                size="small"
                pagination={false} 
                expandable={true}
                />
        </div>
    );
}