// reducers/currentClassWatchSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const currentClassWatchSlice = createSlice({
    name: 'currentClassWatch',
    initialState: {
        isChatEnabled: true,
        value: '',
        newReplyCount: 0, // Adiciona o contador de novas respostas ao estado inicial
    },
    reducers: {
        toggleChat: (state) => {
            state.isChatEnabled = !state.isChatEnabled;
        },
        setCurrentClassWatchRedux: (state, action) => {
            state.value = action.payload;
        },
        incrementNewReplyCount: (state) => { // Action para incrementar o contador de novas respostas
            state.newReplyCount += 1;
        },
        resetNewReplyCount: (state) => { // Action para resetar o contador de novas respostas
            state.newReplyCount = 0;
        },
    },
});

export const { toggleChat, setCurrentClassWatchRedux, incrementNewReplyCount, resetNewReplyCount } = currentClassWatchSlice.actions;

export const selectIsChatEnabled = (state) => state.currentClassWatch.isChatEnabled;
export const selectCurrentClassWatch = (state) => state.currentClassWatch.value;
export const selectNewReplyCount = (state) => state.currentClassWatch.newReplyCount; // Seletor para obter o contador de novas respostas

export default currentClassWatchSlice.reducer;
