import React, { useState } from "react";
import CarrouselCourses from "../../atomos/CarrouselCourses";
import { FloatButton, Modal, Button, Typography, Alert, notification } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import CreateCourseModalOrganisms from "../../organisms/createCourseModalOrganisms/CreateCourseModalOrganisms";
import { AccountService, CourseService } from "cursiverso-client-module/client-web";

export default function DeleteAccountTemplate() {
    const [loading, setLoading] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [api, contextHolder] = notification.useNotification();

    const openSuccessNotification = () => {
        api.open({
            message: 'Sucesso',
            description:
                'Conta excluída com sucesso.',
            duration: 5,
            type: "success"
        });
    };

    const openErrorNotification = () => {
        api.open({
            message: 'Erro',
            description:
                'Não foi possível excluir a conta. Verifique se o email e senha estão corretos.',
            duration: 5,
            type: "error"
        });
    };


    function deleteAccount() {
        AccountService.deleteAccountByEmail(email, password).then((response) => {
            console.log(response);
            setDeleteSuccess(true);
            setEmail("");
            setPassword("");
            openSuccessNotification();
            // reload page
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }).catch((error) => {
            console.log(error);
            openErrorNotification();
        });
    }

    return (
        <div style={{ backgroundColor: "#59399D", width: "100vw", height: "100vh", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", gap: 10 }}>
            {contextHolder}
            <img src="https://static.wixstatic.com/media/3c7062_b3da73e1389847749b303a51fad60bdd~mv2.png/v1/fill/w_119,h_38,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Logo%20Mios%20(3)_edited.png" style={{ width: 120, height: 50 }} />
            <div style={{ backgroundColor: "white", width: "90%", height: 500, borderRadius: 10, alignItems: "center", display: "flex", flexDirection: "column", gap: 10 }}>
                <Typography.Title level={4} style={{ color: "black" }}>Para excluir sua conta digite seu email e senha</Typography.Title>
                <Typography.Text style={{ color: "black" }}>Email</Typography.Text>
                <input type="email" style={{ width: "90%", height: 40, borderRadius: 10 }} onChange={(e) => {
                    setEmail(e.target.value);
                }} />
                <Typography.Text style={{ color: "black" }}>Senha</Typography.Text>
                <input type="password" style={{ width: "90%", height: 40, borderRadius: 10 }}
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }} />
                <Typography.Text style={{ color: "black" }}>Essa ação não poderá ser desfeita; certifique-se de que deseja completá-la.</Typography.Text>
                <Button type="primary" style={{ width: "90%", height: 40, borderRadius: 10 }} onClick={() => {
                    deleteAccount();
                }}>Excluir</Button>
                {
                    deleteSuccess && <Alert message="Conta excluída com sucesso" type="success" />
                }
            </div>
        </div>
    );
}