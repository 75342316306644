import { Avatar, Button, Card, ConfigProvider, Flex, Image, Input, List, Modal, Typography, Upload, message } from 'antd';
import { CaretDownFilled, CaretUpFilled, DislikeFilled, DislikeOutlined, LikeFilled, LikeOutlined, MailOutlined, PlusCircleFilled, SendOutlined, UserOutlined, WechatOutlined } from '@ant-design/icons';
import React from 'react';
import { CommentClassService } from 'cursiverso-client-module/client-web';
import { incrementNewReplyCount, selectCurrentClassWatch, selectNewReplyCount } from '../features/currentClassWatchSlice/currentClassWatchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { color } from 'framer-motion';
import './../index.css'

const CommentItem = ({ comment, reflashData }) => {

    const [showReplies, setShowReplies] = React.useState(false);
    const [newComment, setNewComment] = React.useState();
    const [showInput, setShowInput] = React.useState(false);
    const [CommentId, setCommentId] = React.useState();
    const [UserReplyId, setUserReplyId] = React.useState();
    const [openModal, setOpenModal] = React.useState(false);
    const [commentsNew, setCommentsNew] = React.useState(comment);
    const [previewVisible, setPreviewVisible] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState('');
    const [previewTitle, setPreviewTitle] = React.useState('');
    const [imageList, setImageList] = React.useState([]);

    var imageUrl = imageList[0]?.response?.files[0]?.url;
    console.log(comment)

    const handleResponderClick = () => {
        setShowInput(!showInput);
        // setShowReplies(!showReplies)
        setCommentId(comment._id);
        setUserReplyId(accountDetails?._id.$oid);
        console.log("User Reply ID", UserReplyId)
        console.log("Comment ID", comment._id);
    };
    const handleOpenModal = () => {
        setOpenModal(!openModal);
    }

    const dispatch = useDispatch();
    const currentClassWatchValue = selectCurrentClassWatch(useSelector((state) => state));
    const accountDetails = useSelector(state => state.account);
    const newReplyCount = useSelector(selectNewReplyCount);

    // Função para alternar a exibição das respostas
    const toggleReplies = () => {
        setShowReplies(!showReplies);
    };

    // Função para lidar com o clique no botão de responder
    const handleReply = async () => {
        try {
            if (newComment === '') {
                message.error('A resposta não pode estar vazia!');
                return;
            }
            const response = await CommentClassService.createCommentClass({
                id_class: currentClassWatchValue,
                id_comment_class: CommentId?.$oid,
                id_account: UserReplyId,
                comment: newComment,
                additional_type: 'image',
                additional_type_url: imageUrl,
            });
            setNewComment('');
            setImageList([])
            dispatch(incrementNewReplyCount());
            console.log("reply send", response)
            setShowInput(false);
            message.success('Resposta adicionada com sucesso!');
            reflashData();

        } catch (error) {
            console.error('Error adding reply:', error);
            console.log({
                id_class: currentClassWatchValue,
                id_comment_class: CommentId,
                id_account: UserReplyId,
                comment: newComment,
                additional_type: 'image',
                additional_type_url: imageUrl,
                likes: 0
            });
            message.error('Erro ao adicionar resposta!');
        }
    };

    const calcularTotalComentarios = (comentario) => {
        let total = 1; // Inicia com 1 para incluir o próprio comentário
        if (comentario.replies_comments && comentario.replies_comments.length > 0) {
            comentario.replies_comments.forEach(reply => {
                total += calcularTotalComentarios(reply); // Chamada recursiva para cada resposta
            });
        }
        return total;
    };

    // Função para calcular o tempo decorrido desde a criação do comentário
    const tempoDecorrido = (dataCriacao) => {
        console.log("DT", dataCriacao);
        const agora = new Date(); // Data atual
        const diferenca = agora - new Date(dataCriacao); // Diferença entre a data atual e a data de criação do comentário
        const segundos = Math.floor(diferenca / 1000); // Diferença em segundos
        const minutos = Math.floor(segundos / 60); // Diferença em minutos
        const horas = Math.floor(minutos / 60); // Diferença em horas
        const dias = Math.floor(horas / 24); // Diferença em dias
        const meses = Math.floor(dias / 30); // Diferença em meses
        const anos = Math.floor(meses / 12); // Diferença em anos

        if (anos > 0) {
            return anos === 1 ? 'Há 1 ano' : `Há ${anos} anos`;
        }
        if (meses > 0) {
            return meses === 1 ? 'Há 1 mês' : `Há ${meses} meses`;
        }
        if (dias > 0) {
            return dias === 1 ? 'Há 1 dia' : `Há ${dias} dias`;
        }
        if (horas > 0) {
            return horas === 1 ? 'Há 1 hora' : `Há ${horas} horas`;
        }
        if (minutos > 0) {
            return minutos === 1 ? 'Há 1 minuto' : `Há ${minutos} minutos`;
        }
        return segundos === 1 ? 'Há 1 segundo' : `Há ${segundos} segundos`;
    };


    // Função para lidar com o clique no botão de responder
    const handleReplyClick = (reply) => {
        console.log("respondido", reply); // Aqui você pode executar a lógica desejada ao responder um comentário
    };
    const getBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    function returnPhotoProfile(value){
        if (value === undefined || value === null || value === ""){
            return(
                <Avatar size={50} icon={<UserOutlined />} />
            )
        }else{
            return(
                <Avatar size={50} src={value} />
            )
        }
    }


    return (
        <Card
            style={{ width: '100%', backgroundColor: 'transparent', border: "none", marginLeft: -20 }}
        >
            <Card.Meta
                avatar={returnPhotoProfile(comment.photo_profile)}
                title={
                    <Flex style={{
                        gap: 10,
                        alignItems: "center"
                    }}>
                        <Typography.Text level={5} style={{
                            color: "white",
                            fontSize: 14
                        }}>{comment.name_account}</Typography.Text>
                        <Typography.Text style={{ marginLeft: '5px', color: 'white', fontSize: 10, opacity: 0.6 }}>{tempoDecorrido(new Date(comment.created_at.$date))}</Typography.Text> {/* Mostrando o tempo decorrido */}
                    </Flex>
                }
                description={
                    <Flex vertical style={{
                        width: "auto",
                        overflow: "auto"
                    }}>
                        {comment.additional_type_url !== "" &&
                            <Image src={comment.additional_type_url} style={{
                                width: '100%', height: "250px", borderRadius: '10%',
                                marginTop: 20,
                                marginBottom: 20,
                            }} />
                        }
                        <Typography.Text level={5} style={{
                            color: "white",
                            fontWeight: 300,
                            fontSize: 14, marginBottom: 5
                        }}>{comment.comment}</Typography.Text>
                        <Flex gap={20} vertical style={{ alignItems: "start" }}>
                            <div style={{ height: "100%", gap: 5, display: "flex", cursor: "pointer" }} onClick={handleResponderClick}>
                                <Typography.Text style={{ color: "white", fontSize: 14 }}>Responder</Typography.Text>
                            </div>

                            {showInput && (
                                <Flex gap={10} align='center' style={{
                                    width: "100%",
                                    height: "50px"
                                }}>
                                    {/* <Button type="primary" onClick={handleOpenModal} style={{ color: "white", width: 45, height: 40, background: "rgba(167, 136, 234, 0.41)", borderRadius: 5, justifyContent: "center", alignItems: "center" }}>
                                        <PlusCircleFilled style={{ alignSelf: "center", justifySelf: "center" }} />
                                    </Button>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Modal: {
                                                    headerBg: "rgba(167, 136, 234, 0.41)",
                                                    contentBg: "rgba(167, 136, 234, 0.41)",
                                                },
                                            },
                                        }}
                                    >
                                        <Modal
                                            footer={null}
                                            style={{ background: "rgba(167, 136, 234, 0.41)", height: "200px", width: "150px", maxWidth: "200px", overflow: "hidden", borderRadius: "10px", padding: "10px", top: "55%", position: "absolute", right: 10 }}
                                            open={openModal}
                                            onOk={() => setOpenModal(false)}
                                            onCancel={() => setOpenModal(false)}
                                        >
                                            <Upload
                                                style={{ width: "100%", height: "100%", background: "rgba(167, 136, 234, 0.41)" }}
                                                action="https://cursiverso.azurewebsites.net/image/add-image"
                                                listType="picture-card"
                                                accept=".jpg, .jpeg, .png, .gif"
                                                fileList={imageList}
                                                onPreview={async file => {
                                                    if (!file.url && !file.preview) {
                                                        file.preview = await getBase64(file.originFileObj);
                                                    }
                                                    setPreviewImage(file.url || file.preview);
                                                    setPreviewVisible(true);
                                                    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
                                                }}
                                                onChange={({ fileList }) => {
                                                    setImageList(fileList);
                                                    const timeout = setTimeout(() => {
                                                        if (fileList.length != 0) {
                                                            setOpenModal(false);
                                                        }
                                                        clearTimeout(timeout);
                                                    }, 2000);
                                                }}
                                            >
                                                {imageList.length >= 1 ? null : <div>+</div>}
                                            </Upload>
                                        </Modal>
                                    </ConfigProvider> */}
                                    <input
                                        style={{ backgroundColor: "transparent", width: "100%", maxHeight: 50, padding: "10px", color: "white", border: "none", borderBottom: "1px solid white" }}
                                        onChange={(e) => setNewComment(e.target.value)}
                                        value={newComment}
                                        placeholder="Escreva seu comentário aqui..."
                                    />
                                    <button onClick={handleReply} style={{ width: 60, height: 30, backgroundColor: "#59399D", border: "none", borderRadius: 5, display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                                        <SendOutlined style={{ color: "white" }} />
                                    </button>
                                </Flex>


                            )}

                        </Flex>
                        <Flex vertical style={{
                        }}>
                            {showReplies && comment.replies_comments?.map((reply) => (
                                <List.Item key={reply._id.$oid}>
                                    <List.Item.Meta
                                        avatar={returnPhotoProfile(reply.photo_profile)}
                                        title={<Typography.Text style={{ color: "white", fontSize: 14 }}>{reply.name_account}</Typography.Text>}
                                        description={
                                            <Flex vertical justify='flex-start'>
                                                {reply.additional_type_url !== "" &&
                                                    <Image src={reply.additional_type_url} style={{
                                                        width: '100%', height: "150px", borderRadius: '10%',
                                                        objectFit: "contain",
                                                        marginTop: 20,
                                                        marginBottom: 20,
                                                    }} />
                                                }
                                                <Typography.Title level={5} style={{
                                                    color: "white",
                                                    fontSize: 14,
                                                    fontWeight: 300
                                                }}>{reply.comment}
                                                </Typography.Title>
                                            </Flex>}
                                    />
                                </List.Item>
                            ))}</Flex>

                    </Flex>
                }
            />
            <Flex onClick={toggleReplies} style={{
                background: "transparent",
                marginTop: "20px",
            }}>
                {
                    showReplies ? <Flex gap={10} align='center' justify='flex-start' style={{
                        margin: "auto 20%",
                        background: "transparent",
                        color: "white",
                        cursor: "pointer"
                    }
                    } > <CaretUpFilled />{comment.replies_comments.length} Resposta(s)</Flex > : <Flex gap={10} align='center' justify='flex-start' style={{
                        margin: "auto 20%",
                        background: "transparent",
                        color: "white",
                        cursor: "pointer"

                    }}><CaretDownFilled />{comment.replies_comments.length} Resposta(s)</Flex>}
            </Flex >
        </Card >
    );
};

export default CommentItem;
