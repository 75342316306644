import { Button, Flex, Form, Input, Upload, Image, Checkbox, Alert, Segmented } from "antd";
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import React from "react";
import "./CreateCourseSubModuleModalOrganisms.css";
import { CourseSubModuleService, OpenAPI } from "cursiverso-client-module/client-web";

export default function CreateCourseSubModuleModalOrganisms({ setShowModal, idCourse }) {
    const [image, setImage] = React.useState("https://dashboard.kiwify.com.br/_nuxt/img/module_preview.420776f.png")
    const [loading, setLoading] = React.useState(false)

    const [form] = Form.useForm();

    React.useEffect(() => {
        console.log("ID COURSE", idCourse)
    }, [])

    const props = {
        name: 'file',
        action: OpenAPI.BASE + '/image/add-image',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                console.log("here", info.file.response.files[0].url)
                setImage(info.file.response.files[0].url)
            } else if (info.file.status === 'error') {
                console.log("error", info.file.response);
            }
        },
    };

    const onFinish = (values) => {
        setLoading(true)
        let dataToSend = values
        dataToSend.id_course = idCourse
        dataToSend.image_sub_module = image
        if (values.required_account === "Sim")
            dataToSend.required_account = true
        else
            dataToSend.required_account = false
        console.log('Success:', dataToSend);
        console.log("dataToSend", dataToSend)
        CourseSubModuleService.createCourseSubModule(dataToSend).then((response) => {
            console.log(response)
            form.resetFields()
            setImage(null)
            setShowModal(false)
        }).catch((error) => {
            console.error(error)
        }).finally(() => {
            setLoading(false)
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    React.useEffect(() => {
        // form.validateFields()
        console.log("IMAGE", image)
        if (image) {
            // const upload = document.getElementById("button-upload-id-454632")
            // upload.style.backgroundImage = `url(${image})`
            // upload.style.backgroundSize = "cover"
            //add blur in backgrpound image
        }
    }, [image])

    const options = [
        { label: 'Sim', value: "true" },
        { label: 'Não', value: "false" },
    ];

    return (
        <Form
            form={form}
            name="basic"
            labelAlign="left"
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
            }}
            style={{
            }}
            initialValues={{
                remember: true,
            }}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Flex flex={1}>
                <Flex vertical flex={1}>
                    <Form.Item
                        label="Titulo do modulo"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor, insira o titulo do modulo!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Precisa de uma conta para assistir?"
                        name="required_account"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor, insira se esse modulo precisa de uma conta para ser acessado!',
                            },
                        ]}
                    >
                        <Segmented options={["Sim", "Não"]} block defaultValue="Sim"/>
                    </Form.Item>
                    <Form.Item
                        label="Imagem do módulo"
                        name="image_sub_module"
                        style={{ width: "100%" }}
                        required
                        rules={[
                            {
                                validator: (_, value) => {
                                    console.log("VALIDATE", value)
                                    if (image === null) {
                                        return Promise.reject('Por favor, selecione uma imagem!');
                                    }
                                    return Promise.resolve();
                                }
                            }
                        ]}
                    >
                        <Flex>
                            <Upload className="upload-file" accept={"image/png, image/jpeg, image/jpg"} {...props} maxCount={1}>
                                <Button id="button-upload-id-454632" className="button-upload-file" icon={<UploadOutlined />}>
                                    <div style={{ margin: 10, backgroundColor: "white", borderRadius: 10 }}>
                                        <p>Selecione a imagem</p>
                                        <p>PNG, JPG e JPGE</p>
                                    </div>
                                </Button>
                            </Upload>
                        </Flex>
                    </Form.Item>
                    <Alert
                        style={{ marginBottom: 10 }}
                        icon={<InfoCircleOutlined />}
                        showIcon
                        description="Tamanho recomendado: 720x1040 pixels                        "
                        type="warning"
                    />
                    <Flex style={{ justifyContent: "flex-end", marginTop: 30 }}>
                        <Button loading={loading} type="primary" htmlType="submit" style={{ width: 450 }}>
                            Adicionar módulo
                        </Button>
                    </Flex>
                </Flex>
                <Flex style={{ justifyContent: "center", margin: 10, height: "100%" }} flex={1}>
                    <Image loading="" src={image} style={{ width: 300, height: 400, borderRadius: 10, objectFit: "cover" }} />
                </Flex>
            </Flex>
        </Form>
    )
}