import { Button, Checkbox, Form, Input, Select, Upload } from "antd";
import React from "react";
import { CourseService } from "cursiverso-client-module/client-web";

export default function CreateCourseModalOrganisms({ setShowModal }) {
    const [imageList, setImageList] = React.useState([]);
    const [previewVisible, setPreviewVisible] = React.useState(false);
    const [previewImage, setPreviewImage] = React.useState('');
    const [previewTitle, setPreviewTitle] = React.useState('');
    const [openModal, setOpenModal] = React.useState(true);

    const [loading, setLoading] = React.useState(false)
    const [form] = Form.useForm();

    const getBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const onFinish = (values) => {
        setLoading(true)
        console.log({
            title: values.title,
            index_to_show: 0,
            product: values.type,
            image: imageList[0].response.files[0].url
        })
        CourseService.createCourse({
            title: values.title,
            index_to_show: 0,
            product: values.type,
            image: imageList[0].response.files[0].url
        }).then((response) => {
            console.log(response)
            form.resetFields()
            setShowModal(false)
        }).catch((error) => {
            console.error(error)
        }).finally(() => {
            setLoading(false)
        })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            layout="vertical"
            form={form}
            name="basic"
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            style={{
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Titulo do curso"
                name="title"
                rules={[
                    {
                        required: true,
                        message: 'Por favor, insira o titulo do curso!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Tipo de curso"
                name="type"
                rules={[
                    {
                        required: true,
                        message: 'Por favor, insira o tipo do curso!',
                    },
                ]}
            >
                <Select>
                    <Select.Option value="Pro">Pro</Select.Option>
                    <Select.Option value="Premium">Premium</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item
                label="Foto do curso"
                name="photo"
                rules={[
                    {
                        required: true,
                        message: 'Por favor, insira a foto do curso!',
                    },
                ]}
            >
                <Upload
                    style={{ width: "100%", height: "100%", background: "rgba(167, 136, 234, 0.41)" }}
                    action="https://cursiverso.azurewebsites.net/image/add-image"
                    listType="picture-card"
                    accept=".jpg, .jpeg, .png, .gif"
                    fileList={imageList}
                    onPreview={async file => {
                        if (!file.url && !file.preview) {
                            file.preview = await getBase64(file.originFileObj);
                        }
                        setPreviewImage(file.url || file.preview);
                        setPreviewVisible(true);
                        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
                    }}
                    onChange={({ fileList }) => {
                        setImageList(fileList);
                        const timeout = setTimeout(() => {
                            if (fileList.length != 0) {
                                setOpenModal(false);
                            }
                            clearTimeout(timeout);
                        }, 2000);
                    }}
                >
                    {imageList.length >= 1 ? null : <div>+</div>}
                </Upload>
            </Form.Item>

            <Form.Item
                style={{ display: "flex", justifyContent: "flex-end" }}
            >
                <Button style={{ width: 470 }} type="primary" htmlType="submit" loading={loading}>
                    Criar curso
                </Button>
            </Form.Item>
        </Form>
    )
}