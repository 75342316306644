import React from "react";
import { Carousel, Card, Modal, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import CardCourse from "./CardCourse";
import CreateCourseSubModuleModalOrganisms from "../organisms/createCourseSubModuleModalOrganisms/CreateCourseSubModuleModalOrganisms";
import { CourseSubModuleService, OpenAPI } from "cursiverso-client-module/client-web";
import { useNavigate } from "react-router-dom";
const { Meta } = Card;

const responsiveSettings = [
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },

    },
    {
        breakpoint: 1000,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,

        },
    },
];


export default function CarrouselCourses({ course }) {
    const [subModules, setSubModules] = React.useState([])
    const [open, setOpen] = React.useState(false)

    const navigate = useNavigate();

    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };

    React.useEffect(() => {
        CourseSubModuleService.returnCourseSubModuleById(course._id.$oid).then((response) => {
            setSubModules(response.data)
            console.log("byid", response.data)
        }).catch((error) => {
            console.error(error)
        }).finally(() => {
            console.log("FINALLY")
        })
    }, [])

    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {

    };
    const handleCancel = () => {
        setOpen(false);
    };

    function handleClickCard(_id) {
        console.log(_id)
        setOpen(true)
    }

    function handleClickCardCarrousel(_id) {
        console.log(_id)
        navigate("/class/" + _id)
    }

    const props = {
        name: 'file',
        action: OpenAPI.BASE + '/image/add-image',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                // message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                // message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    function cardsToShow(listSubmodules){
        if (listSubmodules.length == 1) {
            return 1
        }else if (listSubmodules.length == 2) {
            return 2
        }else if (listSubmodules.length == 3) {
            return 3
        }else{
            return 4
        }
    }

    return (
        <>
            <div style={{flexDirection: "row", display: "flex", gap: 10, alignItems: "center", justifyContent: "space-between", marginRight: 30, marginLeft: 30}}>
                <h1>{course.title}</h1>
                {
                    subModules.length > 0 && (
                        <Button type="primary" onClick={showModal}>
                            Adicionar outro módulo
                        </Button>
                    )
                }
            </div>
            {
                subModules.length > 0 ? (
                    <Carousel
                        afterChange={onChange}
                        swipe={true}
                        draggable={true}
                        style={{ width: "100%" }}
                        infinite={true}
                        responsive={responsiveSettings}
                        slidesToShow={cardsToShow(subModules)}
                    >
                        {
                            subModules.map((course) => {
                                return (
                                    <div>
                                        <CardCourse handleOnClick={handleClickCardCarrousel} _id={course._id.$oid} img={course.image_sub_module} is_editable={false} />
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                ) : (
                    <div style={{ justifyContent: "flex-start", display: "flex" }}>
                        <CardCourse _id={"create_card"} img={"https://redcanidsstorage.blob.core.windows.net/redcanids/cc47bd6f-d601-45f8-b32d-a267e104fc17.png"} is_editable={false} handleOnClick={handleClickCard} />
                    </div>
                )
            }
            <Modal
                open={open}
                title={"Criar modulo para o curso - " + course?.title}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[]} 
                style={{ minWidth: 900}}
            >
                <CreateCourseSubModuleModalOrganisms setShowModal={setOpen} idCourse={course._id.$oid} />
            </Modal>
        </>

    );
};