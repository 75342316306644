import { CommentOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import CheckableTag from "antd/es/tag/CheckableTag";
import { CompleteClassAccountService, StartedClassAccountService } from "cursiverso-client-module/client-web";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from 'react-player';
import CommentsView from './CommentsView'
import { selectIsChatEnabled, toggleChat } from "../features/currentClassWatchSlice/currentClassWatchSlice";
import { useDispatch, useSelector } from "react-redux";
export default function ClassWatchVideoAtom({ src, courses, account, courseAllinfo }) {
    const [currentClassWatch, setCurrentClassWatch] = useState(null)
    const [currentTotalSecoundWatched, setCurrentTotalSecoundWatched] = React.useState(0);
    const [currentTotalSecoundWatchedSaved, setCurrentTotalSecoundWatchedSaved] = React.useState(false);
    const [currentTotalSecoundOfCurrentClass, setCurrentTotalSecoundOfCurrentClass] = React.useState(0);
    const [currentTotalSecoundOfCurrentClassFinished, setCurrentTotalSecoundOfCurrentClassFinished] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const handleOpenModal = () => setIsOpen(true);

    const isChatEnabled = useSelector(selectIsChatEnabled);
    const dispatch = useDispatch();

    const handleToggleChat = () => {
        dispatch(toggleChat());
    };


    useEffect(() => {
        if (courses?.lenght > 0) {
            setCurrentClassWatch(courses[0])
        }
    }, [])

    useEffect(() => {
        if (courses?.length > 0) {
            setCurrentTotalSecoundWatched(0);
            setCurrentTotalSecoundWatchedSaved(false);
            setCurrentTotalSecoundOfCurrentClass(0);
            setCurrentTotalSecoundOfCurrentClassFinished(false);
            setCurrentClassWatch(courses[0])
        } else {
            setCurrentClassWatch(null)
        }
    }, [courses])

    function classCompleted() {
        if (account?._id?.$oid == undefined)
            return;
        CompleteClassAccountService.createCompleteClassAccount({
            id_account: account?._id?.$oid,
            id_class: courseAllinfo?._id?.$oid,
            id_course_submodule: courseAllinfo?.id_course_submodule,
        }).then((response) => {
            // fetchCompleted();
        }).catch((error) => {
            // fetchCompleted();
        });
    }

    React.useEffect(() => {
        if (currentTotalSecoundWatched > 15) {
            if (!currentTotalSecoundWatchedSaved) {
                setCurrentTotalSecoundWatchedSaved(true);
                StartedClassAccountService.createStartedClassAccount({
                    id_account: account?._id?.$oid,
                    id_class: courseAllinfo?._id?.$oid,
                    id_course_submodule: courseAllinfo?.id_course_submodule,
                }).then((response) => { }).catch((error) => {
                    console.error(error)
                })
            }
        }
        if (currentTotalSecoundWatched > currentTotalSecoundOfCurrentClass * 0.80) {
            if (!currentTotalSecoundOfCurrentClassFinished) {
                setCurrentTotalSecoundOfCurrentClassFinished(true);
                classCompleted();
            }
        }

    }, [currentTotalSecoundWatched])

    // useEffect(() => {
    //     const exitFullscreenHandler = () => {
    //       if (document.fullscreenElement) {
    //         document.exitFullscreen();
    //       }
    //     };
    
    //     document.addEventListener('fullscreenchange', exitFullscreenHandler);
    //     document.addEventListener('webkitfullscreenchange', exitFullscreenHandler);
    //     document.addEventListener('mozfullscreenchange', exitFullscreenHandler);
    //     document.addEventListener('msfullscreenchange', exitFullscreenHandler);
    
    //     return () => {
    //       document.removeEventListener('fullscreenchange', exitFullscreenHandler);
    //       document.removeEventListener('webkitfullscreenchange', exitFullscreenHandler);
    //       document.removeEventListener('mozfullscreenchange', exitFullscreenHandler);
    //       document.removeEventListener('msfullscreenchange', exitFullscreenHandler);
    //     };
    //   }, []);
    return (
        <Flex vertical >
            <ReactPlayer
                style={{
                    // backgroundImage: "url('https://cursiversostorage.blob.core.windows.net/miosacademyimages/63485af2-9d2e-4091-a2f7-f30155e4eb86.png')",
                    // backgroundSize: "cover",
                }}
                // change color of progress bar
                onBuffer={(value) => {
                    // console.log("onBuffer", value)
                }}
                onBufferEnd={(value) => {
                    // console.log("onBufferEnd", value)
                }}
                onProgress={(value) => {
                    setCurrentTotalSecoundWatched(currentTotalSecoundWatched + 1);
                }}
                onDuration={(value) => {
                    setCurrentTotalSecoundOfCurrentClass(value.toFixed(0));
                }}
                url={currentClassWatch?.url}
                controls={true}
                playing={true}
                height={"70vh"}
                width={"70vw"}
                config={{
                    dailymotion: {
                    },
                    file: {
                        forceHLS: true,
                        hlsOptions: {

                        }
                    },
                }}
            />
            <Flex justify="space-between" style={{
                width: "100%",
                height: "auto",
                display: "flex",
            }}>
                <div style={{ display: "flex", flexDirection: "row", gap: 10, paddingLeft: 10 }}>
                    {
                        courses?.map((course) => {
                            return (
                                <CheckableTag
                                    style={{
                                        maxHeight: "20px",
                                        marginTop: 5,
                                    }}
                                    key={course.title}
                                    checked={currentClassWatch?.title === course.title}
                                    onClick={() => setCurrentClassWatch(course)}
                                >
                                    {course.title}
                                </CheckableTag>

                                // <div 
                                // style={{
                                //     backgroundColor: currentClassWatch?.title === course.title ? "blue" : "gray",
                                //     padding: 10,
                                //     borderRadius: 10,
                                //     cursor: "pointer"

                                // }}
                                // onClick={() => {
                                //     setCurrentClassWatch(course)
                                // }}>
                                //     <h1 style={{color: "white"}}>{course.title}</h1>
                                // </div>
                            )
                        })

                    }

                </div>
                <Button style={{
                    marginTop: 5,
                    width: "50px",
                    height: "26px",
                    borderRadius: "5px",
                    marginRight: 10,
                    background: "#59399D",
                    // boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                    backdropFilter: "blur(10.7px)",
                    webkitBackdropFilter: "blur(10.7px)",
                    borderWidth: 0,
                    // border: "1px solid rgba(141, 57, 232, 0.3)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    opacity: 0.8,
                }}
                    onClick={handleToggleChat}
                >

                    <CommentOutlined style={{ fontSize: "22px", color: "white" }} />
                </Button>
            </Flex>
        </Flex>
    );
}