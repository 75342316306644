import CourseManagerPage from './pages/courseManagerPage/CourseManagerPage';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CourseListSubmoduleCoursesManagerPage from './pages/courseListSubmoduleCoursesManagerPage/CourseListSubmoduleCoursesManagerPage';
import WatchClassPage from './pages/watchClassPage/WatchClassPage';
import { ConfigProvider } from 'antd';
import ReadQrCodePage from './pages/readQrCodePage/ReadQrCodePage';
import StatusProcessPage from './pages/statusProcessPage/StatusProcessPage';
import DeleteAccountPage from './pages/deleteAccountPage/DeleteAccountPage';
import LoginPage from './pages/loginPage/LoginPage';

function App() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            contentBg: "black",
            headerBg: "#171717",
            colorBorder: "black",
            colorText: "white",
            colorTextHeading: "white",
          },
          Tag: {
            colorPrimary: "#59399D",
            colorPrimaryBgHover: "#59399D",
            colorPrimaryHover: "#59399D",
            colorPrimaryActive: "#59399D",
            colorBorder: "white",
            colorText: "white",
          },
          Menu: {
            colorPrimaryBg: "#59399D",
            itemBg: "#49278F",
            colorPrimary: "#59399D",
            colorText: "white",
          },
          Button: {
            colorPrimary: "#59399D",
            colorPrimaryBg: "#59399D",
            colorPrimaryBgHover: "#59399D",
            colorPrimaryHover: "#59399D",
            colorPrimaryActive: "#59399D",
          },
          Input: {
            colorPrimary: "#59399D",
            colorPrimaryBg: "#59399D",
            colorPrimaryBgHover: "#59399D",
            colorPrimaryHover: "#59399D",
            colorPrimaryActive: "#59399D",
          },
        }
      }}>
      <Router>
        <Routes>
          <Route path="/*" element={<ReadQrCodePage />} />
          <Route path="/manager" element={<CourseManagerPage />} />
          <Route path="/class/:id_submodule" element={<CourseListSubmoduleCoursesManagerPage />} />
          <Route path="/aula/" element={<ReadQrCodePage />} />
          <Route path="/aula/:code/:id_course_submodule" element={<WatchClassPage />} />
          <Route path="/aula/:code/:id_course_submodule/:id_class" element={<WatchClassPage />} />
          <Route path="/aula/:code/:id_course_submodule/:id_class/:id_account" element={<WatchClassPage />} />
          <Route path="/status-process" element={<StatusProcessPage />} />
          <Route path='/delete-account' element={<DeleteAccountPage />} />
          <Route path='/login' element={<LoginPage />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
}

export default App;
