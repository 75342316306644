import React, { useState } from "react";
import CarrouselCourses from "../../atomos/CarrouselCourses";
import { FloatButton, Modal, Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import CreateCourseModalOrganisms from "../../organisms/createCourseModalOrganisms/CreateCourseModalOrganisms";
import { CourseService } from "cursiverso-client-module/client-web";

const data = [
  {
    //module
    _id: {
      $oid: "755a4ddd-3dbc-485d-89e0-fc2bae518bfd"
    },
    package_course: "941a2521-01cb-4914-93af-b8d9d7363764",
    index_to_show: 0,
    title: "React JS",
    is_active: true,
    updated_at: "2021-10-10T00:00:00",
    created_at: "2021-10-10T00:00:00",
    sub_modules: [
      {
        _id: {
          $oid: "14bb5c0a-2d5a-4e25-91a2-c2bb6d614190"
        },
        id_cache_image: "14bb5c0a-2d5a-4e25-91a2-c2bb6d614190",
        title: "Começe por aqui",
        is_active: true,
        image_sub_module: "https://redcanidsstorage.blob.core.windows.net/redcanids/d1f72399-9189-4e86-8c46-3af17b6fdbeb.png",
        courses: [
          {
            _id: {
              $oid: "1082e60e-cf12-4d62-bb02-073e9ae35b70"
            },
            id_cache_image: "1082e60e-cf12-4d62-bb02-073e9ae35b70",
            title: "Seja bem vindo",
            description: "Seja bem vindo ao curso de introdução ao React Native",
            section: "Introdução 1.1",
            rating: 5,
            duration_in_minutes: 60,
            image_course: "https://lh3.googleusercontent.com/p/AF1QipOAvyV2rLy9udz9jOejc5VxTrffOX9d8awU-ROQ=s1360-w1360-h1020",
            thumbnail: "https://lh3.googleusercontent.com/p/AF1QipOAvyV2rLy9udz9jOejc5VxTrffOX9d8awU-ROQ=s1360-w1360-h1020",
            course_url: "https://redcanidsstorage.blob.core.windows.net/redcanids/Seque%CC%82ncia%2001.mp4",
            materials: [{
              title: "Material 1",
              url: "https://redcanidsstorage.blob.core.windows.net/redcanids/Seque%CC%82ncia%2001.mp4",
              type: "pdf",
            }],
            expected_realease_date: "2021-10-10T00:00:00",
            updated_at: "2021-10-10T00:00:00",
            created_at: "2021-10-10T00:00:00",
          },
          {
            _id: {
              $oid: "2b426dc7-23e2-42c1-982c-e687b038e0a8"
            },
            id_cache_image: "2b426dc7-23e2-42c1-982c-e687b038e0a8",
            title: "Seja bem vindo 2",
            description: "Seja bem vindo ao curso de introdução ao React Native",
            section: "Introdução 1.1",
            rating: 5,
            duration_in_minutes: 60,
            image_course: "https://lh3.googleusercontent.com/p/AF1QipOAvyV2rLy9udz9jOejc5VxTrffOX9d8awU-ROQ=s1360-w1360-h1020",
            thumbnail: "https://lh3.googleusercontent.com/p/AF1QipOAvyV2rLy9udz9jOejc5VxTrffOX9d8awU-ROQ=s1360-w1360-h1020",
            course_url: "https://redcanidsstorage.blob.core.windows.net/redcanids/Test 4.mp4",
            materials: [{
              title: "Material 1",
              url: "https://redcanidsstorage.blob.core.windows.net/redcanids/Seque%CC%82ncia%2001.mp4",
              type: "pdf",
            }],
            expected_realease_date: "2021-10-10T00:00:00",
            updated_at: "2021-10-10T00:00:00",
            created_at: "2021-10-10T00:00:00",
          },
          {
            _id: {
              $oid: "97118848-5e36-4ddf-af3b-55317bb24ea9"
            },
            id_cache_image: "97118848-5e36-4ddf-af3b-55317bb24ea9",
            title: "Seja bem vindo 3",
            description: "Seja bem vindo ao curso de introdução ao React Native 3",
            section: "Introdução 1.3",
            rating: 5,
            duration_in_minutes: 60,
            image_course: "https://lh3.googleusercontent.com/p/AF1QipOAvyV2rLy9udz9jOejc5VxTrffOX9d8awU-ROQ=s1360-w1360-h1020",
            thumbnail: "https://lh3.googleusercontent.com/p/AF1QipOAvyV2rLy9udz9jOejc5VxTrffOX9d8awU-ROQ=s1360-w1360-h1020",
            course_url: "https://redcanidsstorage.blob.core.windows.net/redcanids/Teste 5.mp4",
            materials: [{
              title: "Material 1",
              url: "https://redcanidsstorage.blob.core.windows.net/redcanids/Seque%CC%82ncia%2001.mp4",
              type: "pdf",
            }],
            expected_realease_date: "2021-10-10T00:00:00",
            updated_at: "2021-10-10T00:00:00",
            created_at: "2021-10-10T00:00:00",
          }
        ],
        updated_at: "2021-10-10T00:00:00",
        created_at: "2021-10-10T00:00:00",
      },
      {
        _id: {
          $oid: "876164e4-d7f2-48b4-88ad-c0bd643f983c"
        },
        id_cache_image: "876164e4-d7f2-48b4-88ad-c0bd643f983c",
        title: "Introdução 4",
        is_active: true,
        image_sub_module: "https://redcanidsstorage.blob.core.windows.net/redcanids/b06aee13-20e4-4af8-b82c-d975abd1b097.png",
        courses: [
          {
            _id: {
              $oid: "a08e9c7d-b8c0-4012-8b33-d39ac64febea"
            },
            id_cache_image: "a08e9c7d-b8c0-4012-8b33-d39ac64febea",
            title: "Seja bem vindo 4",
            description: "Seja bem vindo ao curso de introdução ao React Native",
            section: "Introdução 1.1",
            rating: 5,
            duration_in_minutes: 60,
            image_course: "https://lh3.googleusercontent.com/p/AF1QipOAvyV2rLy9udz9jOejc5VxTrffOX9d8awU-ROQ=s1360-w1360-h1020",
            thumbnail: "https://lh3.googleusercontent.com/p/AF1QipOAvyV2rLy9udz9jOejc5VxTrffOX9d8awU-ROQ=s1360-w1360-h1020",
            course_url: "https://redcanidsstorage.blob.core.windows.net/redcanids/Seque%CC%82ncia%2001.mp4",
            materials: [{
              title: "Material 1",
              url: "https://redcanidsstorage.blob.core.windows.net/redcanids/Seque%CC%82ncia%2001.mp4",
              type: "pdf",
            }],
            expected_realease_date: "2021-10-10T00:00:00",
            updated_at: "2021-10-10T00:00:00",
            created_at: "2021-10-10T00:00:00",
          }
        ],
        updated_at: "2021-10-10T00:00:00",
        created_at: "2021-10-10T00:00:00",
      },
      {
        _id: {
          $oid: "876164e4-d7f2-48b4-88ad-c0bd643f983c"
        },
        id_cache_image: "876164e4-d7f2-48b4-88ad-c0bd643f983c",
        title: "Introdução 4",
        is_active: true,
        image_sub_module: "https://redcanidsstorage.blob.core.windows.net/redcanids/92115217-040a-417f-b88f-90757c4fbe66.png",
        courses: [
          {
            _id: {
              $oid: "a08e9c7d-b8c0-4012-8b33-d39ac64febea"
            },
            id_cache_image: "a08e9c7d-b8c0-4012-8b33-d39ac64febea",
            title: "Seja bem vindo 4",
            description: "Seja bem vindo ao curso de introdução ao React Native",
            section: "Introdução 1.1",
            rating: 5,
            duration_in_minutes: 60,
            image_course: "https://lh3.googleusercontent.com/p/AF1QipOAvyV2rLy9udz9jOejc5VxTrffOX9d8awU-ROQ=s1360-w1360-h1020",
            thumbnail: "https://lh3.googleusercontent.com/p/AF1QipOAvyV2rLy9udz9jOejc5VxTrffOX9d8awU-ROQ=s1360-w1360-h1020",
            course_url: "https://redcanidsstorage.blob.core.windows.net/redcanids/Seque%CC%82ncia%2001.mp4",
            materials: [{
              title: "Material 1",
              url: "https://redcanidsstorage.blob.core.windows.net/redcanids/Seque%CC%82ncia%2001.mp4",
              type: "pdf",
            }],
            expected_realease_date: "2021-10-10T00:00:00",
            updated_at: "2021-10-10T00:00:00",
            created_at: "2021-10-10T00:00:00",
          }
        ],
        updated_at: "2021-10-10T00:00:00",
        created_at: "2021-10-10T00:00:00",
      },
      {
        _id: {
          $oid: "876164e4-d7f2-48b4-88ad-c0bd643f983c"
        },
        id_cache_image: "876164e4-d7f2-48b4-88ad-c0bd643f983c",
        title: "Introdução 4",
        is_active: true,
        image_sub_module: "https://redcanidsstorage.blob.core.windows.net/redcanids/dbaf175f-02a5-4183-8aad-e25995218b53.png",
        courses: [
          {
            _id: {
              $oid: "a08e9c7d-b8c0-4012-8b33-d39ac64febea"
            },
            id_cache_image: "a08e9c7d-b8c0-4012-8b33-d39ac64febea",
            title: "Seja bem vindo 4",
            description: "Seja bem vindo ao curso de introdução ao React Native",
            section: "Introdução 1.1",
            rating: 5,
            duration_in_minutes: 60,
            image_course: "https://lh3.googleusercontent.com/p/AF1QipOAvyV2rLy9udz9jOejc5VxTrffOX9d8awU-ROQ=s1360-w1360-h1020",
            thumbnail: "https://lh3.googleusercontent.com/p/AF1QipOAvyV2rLy9udz9jOejc5VxTrffOX9d8awU-ROQ=s1360-w1360-h1020",
            course_url: "https://redcanidsstorage.blob.core.windows.net/redcanids/Seque%CC%82ncia%2001.mp4",
            materials: [{
              title: "Material 1",
              url: "https://redcanidsstorage.blob.core.windows.net/redcanids/Seque%CC%82ncia%2001.mp4",
              type: "pdf",
            }],
            expected_realease_date: "2021-10-10T00:00:00",
            updated_at: "2021-10-10T00:00:00",
            created_at: "2021-10-10T00:00:00",
          }
        ],
        updated_at: "2021-10-10T00:00:00",
        created_at: "2021-10-10T00:00:00",
      },
      {
        _id: {
          $oid: "876164e4-d7f2-48b4-88ad-c0bd643f983c"
        },
        id_cache_image: "876164e4-d7f2-48b4-88ad-c0bd643f983c",
        title: "Introdução 4",
        is_active: true,
        image_sub_module: "https://redcanidsstorage.blob.core.windows.net/redcanids/168742f9-fd98-49db-b22e-43349346a552.png",
        courses: [
          {
            _id: {
              $oid: "a08e9c7d-b8c0-4012-8b33-d39ac64febea"
            },
            id_cache_image: "a08e9c7d-b8c0-4012-8b33-d39ac64febea",
            title: "Seja bem vindo 4",
            description: "Seja bem vindo ao curso de introdução ao React Native",
            section: "Introdução 1.1",
            rating: 5,
            duration_in_minutes: 60,
            image_course: "https://lh3.googleusercontent.com/p/AF1QipOAvyV2rLy9udz9jOejc5VxTrffOX9d8awU-ROQ=s1360-w1360-h1020",
            thumbnail: "https://lh3.googleusercontent.com/p/AF1QipOAvyV2rLy9udz9jOejc5VxTrffOX9d8awU-ROQ=s1360-w1360-h1020",
            course_url: "https://redcanidsstorage.blob.core.windows.net/redcanids/Seque%CC%82ncia%2001.mp4",
            materials: [{
              title: "Material 1",
              url: "https://redcanidsstorage.blob.core.windows.net/redcanids/Seque%CC%82ncia%2001.mp4",
              type: "pdf",
            }],
            expected_realease_date: "2021-10-10T00:00:00",
            updated_at: "2021-10-10T00:00:00",
            created_at: "2021-10-10T00:00:00",
          }
        ],
        updated_at: "2021-10-10T00:00:00",
        created_at: "2021-10-10T00:00:00",
      },
      {
        _id: {
          $oid: "876164e4-d7f2-48b4-88ad-c0bd643f983c"
        },
        id_cache_image: "876164e4-d7f2-48b4-88ad-c0bd643f983c",
        title: "Introdução 4",
        is_active: true,
        image_sub_module: "https://redcanidsstorage.blob.core.windows.net/redcanids/d4ecc2ca-8e2a-428c-ba2f-92ff70cca3b8.png",
        courses: [
          {
            _id: {
              $oid: "a08e9c7d-b8c0-4012-8b33-d39ac64febea"
            },
            id_cache_image: "a08e9c7d-b8c0-4012-8b33-d39ac64febea",
            title: "Seja bem vindo 4",
            description: "Seja bem vindo ao curso de introdução ao React Native",
            section: "Introdução 1.1",
            rating: 5,
            duration_in_minutes: 60,
            image_course: "https://lh3.googleusercontent.com/p/AF1QipOAvyV2rLy9udz9jOejc5VxTrffOX9d8awU-ROQ=s1360-w1360-h1020",
            thumbnail: "https://lh3.googleusercontent.com/p/AF1QipOAvyV2rLy9udz9jOejc5VxTrffOX9d8awU-ROQ=s1360-w1360-h1020",
            course_url: "https://redcanidsstorage.blob.core.windows.net/redcanids/Seque%CC%82ncia%2001.mp4",
            materials: [{
              title: "Material 1",
              url: "https://redcanidsstorage.blob.core.windows.net/redcanids/Seque%CC%82ncia%2001.mp4",
              type: "pdf",
            }],
            expected_realease_date: "2021-10-10T00:00:00",
            updated_at: "2021-10-10T00:00:00",
            created_at: "2021-10-10T00:00:00",
          }
        ],
        updated_at: "2021-10-10T00:00:00",
        created_at: "2021-10-10T00:00:00",
      }
    ],
  }
]

export default function CourseManagerTemplate() {

  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    if (open)
      return;
    CourseService.returnAllCourses().then((response) => {
      setCourses(response.data)
    }).catch((error) => {
      console.error(error)
    }).finally(() => {
      setLoading(false)
    })
  }, [open]);

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      {
        courses.map((module) => {
          return (
            <div>
              <CarrouselCourses course={module} />
            </div>
          )
        })
      }
      <Modal
        open={open}
        title="Novo curso"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <CreateCourseModalOrganisms setShowModal={setOpen} />
      </Modal>
      <FloatButton onClick={() => setOpen(true)} icon={<PlusCircleOutlined />} />
    </div>
  );
}