import { createSlice } from '@reduxjs/toolkit'

export const drawerFeatures = createSlice({
    name: 'drawer',
    initialState: true,
    reducers: {
        openDrawer: (state, action) => {
            state = true
            return state
        },

        closeDrawer: (state, action) => {
            state = false
            return state
        },
    },
})

export const { openDrawer, closeDrawer } = drawerFeatures.actions

export default drawerFeatures.reducer