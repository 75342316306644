import { Button, Collapse, Modal } from "antd";
import React, { useEffect } from "react";
import CreateNewClassModalOrganisms from "../../organisms/createNewClassModalOrganisms/CreateNewClassModalOrganisms";
import { ClassService } from "cursiverso-client-module/client-web";
import { useSearchParams } from 'react-router-dom';
import { useParams } from "react-router-dom";
import TextBoldH3 from "../../atomos/TextBoldH3";
import TextBoldH4 from "../../atomos/TextBoldH4";
import TextBoldH5 from "../../atomos/TextBoldH5";


export default function CourseListSubmoduleCoursesManagerTemplate() {

    const [open, setOpen] = React.useState(false)
    const [groupBy, setGroupBy] = React.useState({})
    const { id_submodule } = useParams();


    const handleOk = () => {
        setOpen(false);
    };
    const handleCancel = () => {
        setOpen(false);
    };

    function handleCreateNewClass() {
        console.log("create new class")
        setOpen(true)
    }

    //group by  section key 

    function groupByKey(objectArray, property) {
        return objectArray.reduce(function (acc, obj) {
            let key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    useEffect(() => {
        ClassService.returnAllClassesByIdCourseSubmodule(id_submodule).then((response) => {
            let teste = response.data
            teste = groupByKey(teste, "section")
            console.log("teste", teste)
            setGroupBy(teste)
        }).catch((error) => {
            console.error(error)
        }).finally(() => {
            console.log("FINALLY")
        })
    }, [])

    return (
        <div>
            <Button onClick={() => handleCreateNewClass()} type="primary" >Criar aula</Button>
            <Collapse defaultActiveKey={["1"]} size="large">
                {
                    Object.keys(groupBy).map((key) => {
                        return (
                            <Collapse.Panel header={key} key={key}>
                                {
                                    groupBy[key].map((item) => {
                                        return (
                                            <div style={{flexDirection: "row", display: "flex", gap: 10}}>
                                                <div>
                                                    <img src={item.image_course} alt="course" style={{ width: 100, height: 100, objectFit: "cover", borderRadius: 10 }} />
                                                </div>
                                                <div>
                                                    <h4>{item.title}</h4>
                                                    <p>{item.description}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Collapse.Panel>
                        )
                    })
                }
            </Collapse>
            <Modal
                open={open}
                title="Nova aula"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
            >
                <CreateNewClassModalOrganisms handleOnClose={handleCancel} handleOnCreate={handleOk} id_course_submodule={id_submodule}/>
            </Modal>
        </div>
    )
}