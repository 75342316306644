import React, { useEffect } from "react";
import "./ReadQrCodeTemplate.css";
import { QRCode, Typography } from 'antd';
import TextBoldH3 from "../../atomos/TextBoldH3";
import TextBoldH5 from "../../atomos/TextBoldH5";
import { ClassWatchDesktopService } from "cursiverso-client-module/client-web";
import { useNavigate } from "react-router-dom";

export default function ReadQrCodeTemplate() {
    const [code, setCode] = React.useState("");
    const codeRef = React.useRef("");
    const navigation = useNavigate();

    window.onload = function () {

    }

    useEffect(() => {
        if (codeRef.current === "") {
            ClassWatchDesktopService.createClassWatchDesktop().then((response) => {
                codeRef.current = response.data;
                console.log(response.data);
                setCode(response.data);
                console.log(response.data);
            }).catch((error) => {
                console.error(error);
            });
        }
        const timeLoop = setInterval(() => {
            if (codeRef.current === "")
                return;
            ClassWatchDesktopService.returnClassWatchDesktopByCode(codeRef.current).then((response) => {
                if (response.data.read === true) {
                    clearInterval(timeLoop);
                    const { id_course_submodule, id_class } = response.data.id_course_submodule.split("&").length > 1 ? { id_course_submodule: response.data.id_course_submodule.split("&")[0], id_class: response.data.id_course_submodule.split("&")[1] } : { id_course_submodule: response.data.id_course_submodule, id_class: "" };
                    if (response.data.id_course_submodule.split("&").length > 1) {
                        console.log("foi escolhido uma aula especifica")
                        navigation("/aula/" + codeRef.current + "/" + id_course_submodule + "/" + id_class);
                    } else {
                        console.log("foi escolhido um modulo")
                        navigation("/aula/" + codeRef.current + "/" + id_course_submodule);
                    }
                }

                // if (response.data.read === true) {
                //     clearInterval(timeLoop);
                //     navigation("/aula/" + codeRef.current + "/" + response.data.id_course_submodule);
                // }
            }).catch((error) => {
                console.error(error);
            })
        }, 2000);

        return () => {

        };
    }, []);

    return (
        <div style={{ height: "100vh", margin: 0, padding: 0, backgroundColor: "black", display: "flex", justifyContent: "center", alignItems: "center", position: "relative", overflow: "hidden", backgroundImage: `url("https://cursiversostorage.blob.core.windows.net/miosacademyimages/acd9454a-71bf-4ef9-87fc-1e0e82eadd3c.png")`, backgroundSize: "cover" }}>
            <div className="card-qrcode-invite shadow-default">
                <div style={{ justifyContent: "center", alignItems: "center", display: "flex", height: 200 }}>
                    <Typography.Title level={3} style={{ color: "white", textAlign: "center", fontWeight: 100 }}>Como assistir aulas da Mios Academy</Typography.Title>
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                    <div className="card-qrcode-invite-children">
                        <div style={{position: "relative"}}>
                            <img src="https://cursiversostorage.blob.core.windows.net/miosacademyimages/1ba26f27-9bfd-4c6e-927d-812a9e684aee.png" alt="course" className="smartphone-cloud" />
                            <img src="https://cursiversostorage.blob.core.windows.net/miosacademyimages/dcb7505f-a649-45c9-b856-6f7d2404a7b4.png" alt="course" style={{ width: 42, height: 42, position: "absolute", top: 35, right: 10}} />
                        </div>
                        <Typography.Title level={3} style={{ color: "white", textAlign: "center", fontWeight: 100 }}>1° Passo</Typography.Title>
                        <Typography.Title level={5} style={{ color: "white", textAlign: "center", fontWeight: 100 }}>Abra o aplicativo da Mios Academy</Typography.Title>
                        <Typography.Title level={5} style={{ color: "white", textAlign: "center", fontWeight: 100 }}>e escolha as aulas</Typography.Title>
                    </div>
                    <div className="card-qrcode-invite-children">
                        <div className="smartphone-cloud">
                            <QRCode
                                errorLevel="H"
                                color="#ffffff"
                                size={250}
                                value={code}
                                icon="https://cursiversostorage.blob.core.windows.net/miosacademyimages/a0736929-9cee-4033-947e-5e7d6c12b462.png"
                            />
                        </div>
                        <Typography.Title level={3} style={{ color: "white", textAlign: "center", fontWeight: 100 }}>2° Passo</Typography.Title>
                        <Typography.Title level={5} style={{ color: "white", textAlign: "center", fontWeight: 100 }}>Aponte a câmera para </Typography.Title>
                        <Typography.Title level={5} style={{ color: "white", textAlign: "center", fontWeight: 100 }}>escanear o código acima</Typography.Title>
                        {/* <Typography.Title level={5} style={{ color: "white", textAlign: "center", fontWeight: 100 }}>ou digíte o código {<span style={{ fontWeight: 900 }}>{code}</span>} na app.</Typography.Title> */}
                    </div>
                    <div className="card-qrcode-invite-children">
                        <img src="https://cursiversostorage.blob.core.windows.net/miosacademyimages/3bcbc872-639f-4eee-94b7-e16d088d4b43.png" alt="course" className="smartphone-cloud" style={{width: 350}}/>
                        <Typography.Title level={3} style={{ color: "white", textAlign: "center", fontWeight: 100 }}>3° Passo</Typography.Title>
                        <Typography.Title level={5} style={{ color: "white", textAlign: "center", fontWeight: 100 }}>Pronto você já pode assistir</Typography.Title>
                        <Typography.Title level={5} style={{ color: "white", textAlign: "center", fontWeight: 100 }}>as aulas escolhidas</Typography.Title>
                    </div>
                </div>
            </div>
        </div>
    );
}