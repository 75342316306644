import { createSlice } from '@reduxjs/toolkit';

// Create a slice to manage the account details
const accountSlice = createSlice({
    name: 'account',
    initialState: {},
    reducers: {
        setAccountDetails: (state, action) => ({
            ...state,
            ...action.payload,
        }),
    },
});

// Extract the action creator
export const { setAccountDetails } = accountSlice.actions;

// Export the reducer
export default accountSlice.reducer;

