import React, { useState, useEffect, useMemo } from 'react';
import { CommentClassService } from 'cursiverso-client-module/client-web';
import { selectCurrentClassWatch, selectNewReplyCount } from '../features/currentClassWatchSlice/currentClassWatchSlice';
import { Button, Card, Input, List, Modal, Typography, Spin, Flex, Space, Badge, ConfigProvider, Avatar, Divider, message, Upload } from 'antd';
import CommentItem from './CommentItem';
import { Skeleton } from 'antd';
import { CommentOutlined, PlusCircleFilled, SendOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import './../index.css';

const Post = () => {
    const [newComment, setNewComment] = useState("");
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [avatarColor, setAvatarColor] = useState(null);
    const currentClassWatchValue = selectCurrentClassWatch(useSelector((state) => state));
    const accountDetails = useSelector(state => state.account);
    const newReplyCount = useSelector(selectNewReplyCount);
    const [openModal, setOpenModal] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [imageList, setImageList] = useState([]);

    var imageUrl = imageList[0]?.response?.files[0]?.url;

    const handleCancel = () => setPreviewVisible(false);

    const handleOpenModal = () => {
        setOpenModal(!openModal);
    }
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await CommentClassService.returnCommentClassByClassId(currentClassWatchValue);
            setComments(response.data);
        } catch (error) {
            console.error('Error fetching comments:', error);
        } finally {
            setLoading(false);
        }
    };


    const randomColor = () => {
        const letters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const formatNumber = (num) => {
        if (num >= 1e6) {
            return `${Math.floor(num / 1e6)}M`;
        } else if (num >= 1e9) {
            return `${Math.floor(num / 1e9)}B`;
        } else {
            return num.toString();
        }
    };

    const totalComments = formatNumber(comments.length);

    const getBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };
    // const memoizedFetchData = useMemo(() => fetchData, [currentClassWatchValue]);

    useEffect(() => {
        if (accountDetails.photo_profile) {
            setAvatarColor(null);
        } else {
            const color = randomColor();
            setAvatarColor(color);
        }
    }, [accountDetails]);

    const addComment = async () => {
        const { _id: { $oid: idAccount } } = accountDetails;
        try {
            if (newComment !== "") {
                await CommentClassService.createCommentClass({ id_class: currentClassWatchValue, id_account: idAccount, comment: newComment, additional_type: "image", additional_type_url: imageUrl });
                message.success('Comentário adicionado com sucesso!')
                setImageList([])
                fetchData();
            }
            setNewComment("");
            // memoizedFetchData();
            if (newComment === "") {
                message.error('Erro ao adicionar comentário!');
            }
        } catch (error) {
            console.error('Error adding comment:', error);
            message.error('Erro ao adicionar comentário!');
        }
    };


    useEffect(() => {
        fetchData();
    }, [currentClassWatchValue])

    useEffect(() => {
        // fetchData();
    }, [newReplyCount])

    useEffect(() => {
        if (accountDetails.photo_profile) {
            setAvatarColor(null);
        } else {
            const color = randomColor();
            setAvatarColor(color);
        }
    }, [accountDetails]);

    return (
        <Flex vertical style={{
            padding: "2%",
            height: "100%",
            width: "90%",
            backgroundColor: "#23211E",
            borderRadius: 10,
        }}>
            <Flex align='normal' gap={10} style={{
                marginTop: 5,
                alignItems: "center"
            }}>
                <Typography.Text level={4} style={{
                    color: "white",
                    textAlign: "center",
                    fontWeight: 700,
                    fontSize: 18
                }}>Comentários</Typography.Text>
                <Typography.Text style={{
                    color: "white",
                    textAlign: "center",
                    fontWeight: 700,
                    fontSize: 14,
                    opacity: 0.6,
                }}>{totalComments}</Typography.Text>
                {/* <Badge count={totalComments} style={{
                    backgroundColor: '#59399D',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                    backdropFilter: 'blur(10.7px)',
                    webkitBackdropFilter: 'blur(10.7px)',
                    border: '1px solid #59399D',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: 0.8,
                    marginBottom: 10,
                }}>
                </Badge> */}
            </Flex>
            <Flex vertical align='center' justify='center' style={{
                overflow: "auto"
            }}>
                {loading ? (
                    <>
                        <Card
                            style={{ width: '95%', backgroundColor: "transparent", border: "none" }}
                        >
                            <Card.Meta
                                avatar={<Skeleton avatar={{ size: 'large', shape: 'square' }} />}
                                title={<Skeleton active paragraph={{ rows: 1 }} />}
                                description={
                                    <Skeleton active>
                                        <p><Skeleton active block width={200} /></p>
                                        <Skeleton active block width={100} />
                                    </Skeleton>
                                }
                            />
                        </Card>
                        <Card
                            style={{ width: '95%', backgroundColor: "transparent", border: "none" }}
                        >
                            <Card.Meta
                                avatar={<Skeleton avatar={{ size: 'large', shape: 'square' }} />}
                                title={<Skeleton active paragraph={{ rows: 1 }} />}
                                description={
                                    <Skeleton active>
                                        <p><Skeleton active block width={200} /></p>
                                        <Skeleton active block width={100} />
                                    </Skeleton>
                                }
                            />
                        </Card>
                    </>
                ) : comments.length > 0 ? (
                    <List
                        className='scroll-container'
                        style={{
                            minHeight: 620,
                            maxHeight: 620,
                            paddingBottom: 60,
                            minWidth: "99%",
                            maxWidth: "99%",
                            overflow: "auto",
                        }}
                        dataSource={comments}
                        renderItem={(comment) => (
                            <Flex align='center' style={{
                                width: "100%",
                                height: "100%",
                            }}>
                                <List.Item style={{
                                    width: "100%",
                                    height: "100%",
                                }}>
                                    <CommentItem comment={comment} reflashData={fetchData}/>
                                </List.Item>
                            </Flex>
                        )}
                    />
                ) : (
                    <Typography.Text style={{

                        textAlign: "center",
                        fontSize: 16,
                        color: "#59399D",
                        fontWeight: 'bold',
                    }}>
                        Seja o primeiro a comentar essa aula!
                    </Typography.Text>
                )}
            </Flex>
            <Flex vertical align='center' style={{
                width: "100%",
            }}>
                <Flex style={{
                    height: "10%",
                    width: "90%",
                    padding: "2%",
                    gap: 10,
                    bottom: 0,
                    left: 0,
                    position: "absolute",
                    backgroundColor: "#59399D",
                    color: "white",
                    justifyContent: "center",
                    alignItems: "center"
                }}> {accountDetails.photo_profile ? (
                    <Avatar src={accountDetails.photo_profile} style={{
                        minWidth: 60,
                        height: 60,
                        borderRadius: 100,
                        color: "white",
                        fontSize: 26,
                        background: "#59399D"
                    }} />
                ) : (
                    <></>
                )}
                    <Button type="primary" onClick={handleOpenModal} style={{ color: "white", width: 45, height: 40, background: "rgba(167, 136, 234, 0.41)", borderRadius: 5, justifyContent: "center", alignItems: "center" }}>
                        <PlusCircleFilled style={{ alignSelf: "center", justifySelf: "center" }} />
                    </Button>
                    <ConfigProvider
                        theme={{
                            components: {
                                Modal: {
                                    headerBg: "#59399D",
                                    contentBg: "#59399D",
                                },
                            },
                        }}
                    >
                        <Modal
                            footer={null}
                            style={{ background: "#59399D", height: "200px", width: "150px", maxWidth: "200px", overflow: "hidden", borderRadius: "10px", padding: "10px", top: "55%", position: "absolute", right: 10 }}
                            open={openModal}
                            onOk={() => setOpenModal(false)}
                            onCancel={() => setOpenModal(false)}
                        >
                            <Upload
                                style={{ width: "100%", height: "100%", background: "#59399D" }}
                                action="https://cursiverso.azurewebsites.net/image/add-image"
                                listType="picture-card"
                                accept=".jpg, .jpeg, .png, .gif"
                                fileList={imageList}
                                onPreview={async file => {
                                    if (!file.url && !file.preview) {
                                        file.preview = await getBase64(file.originFileObj);
                                    }
                                    setPreviewImage(file.url || file.preview);
                                    setPreviewVisible(true);
                                    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
                                }}
                                onChange={({ fileList }) => {
                                    setImageList(fileList);
                                    const timeout = setTimeout(() => {
                                        if (fileList.length != 0) {
                                            setOpenModal(false);
                                        }
                                        clearTimeout(timeout);
                                    }, 2000);
                                }}
                            >
                                {imageList.length >= 1 ? null : <div>+</div>}
                            </Upload>
                        </Modal>
                    </ConfigProvider>
                    <input className='input-new-comment' type='text' placeholder="Escreva seu comentário aqui..."
                        required
                        onChange={e => setNewComment(e.target.value)}
                        value={newComment}
                    />
                    <button onClick={() => {
                        addComment()
                        setNewComment("")
                    }} className='button-send-new' style={{
                        marginRight: "4%",
                    }}>
                        <SendOutlined />
                    </button>
                </Flex>
            </Flex>
        </Flex >
    );
};

export default Post;
