import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    comments: [],
};

const commentsSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {
        addComment(state, action) {
            state.comments.push(action.payload);
        },
        updateIdClass(state, action) {
            const { commentId, newIdClass } = action.payload;
            const commentToUpdate = state.comments.find(comment => comment._id === commentId);
            if (commentToUpdate) {
                commentToUpdate.id_class = newIdClass;
            }
        },
        updateIdCommentClass(state, action) {
            const { commentId, newIdCommentClass } = action.payload;
            const commentToUpdate = state.comments.find(comment => comment._id === commentId);
            if (commentToUpdate) {
                commentToUpdate.id_comment_class = newIdCommentClass;
            }
        },
        updateIdAccount(state, action) {
            const { commentId, newIdAccount } = action.payload;
            const commentToUpdate = state.comments.find(comment => comment._id === commentId);
            if (commentToUpdate) {
                commentToUpdate.id_account = newIdAccount;
            }
        },
        updateComment(state, action) {
            const { commentId, newComment } = action.payload;
            const commentToUpdate = state.comments.find(comment => comment._id === commentId);
            if (commentToUpdate) {
                commentToUpdate.comment = newComment;
            }
        },
        updateAdditionalType(state, action) {
            const { commentId, newAdditionalType } = action.payload;
            const commentToUpdate = state.comments.find(comment => comment._id === commentId);
            if (commentToUpdate) {
                commentToUpdate.additional_type = newAdditionalType;
            }
        },
        updateAdditionalTypeUrl(state, action) {
            const { commentId, newAdditionalTypeUrl } = action.payload;
            const commentToUpdate = state.comments.find(comment => comment._id === commentId);
            if (commentToUpdate) {
                commentToUpdate.additional_type_url = newAdditionalTypeUrl;
            }
        },
        updateLikes(state, action) {
            const { commentId, newLikes } = action.payload;
            const commentToUpdate = state.comments.find(comment => comment._id === commentId);
            if (commentToUpdate) {
                commentToUpdate.likes = newLikes;
            }
        },
    },
});

export const {
    addComment,
    updateIdClass,
    updateIdCommentClass,
    updateIdAccount,
    updateComment,
    updateAdditionalType,
    updateAdditionalTypeUrl,
    updateLikes,
} = commentsSlice.actions;

export default commentsSlice.reducer;
